import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import './SalaryCalculation.css';

function SalaryCalculation() {
  const navigate = useNavigate();
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [salesData, setSalesData] = useState(null);
  const [areas, setAreas] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [months, setMonths] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        
        setExcelData(jsonData);
        
        // 提取唯一的月份、地区和员工名称
        const uniqueMonths = [...new Set(Object.keys(jsonData[0]).filter(key => 
          key.includes('月') && !key.includes('累计')
        ))];
        const uniqueAreas = [...new Set(jsonData.map(item => item['地区']))];
        const uniqueEmployees = [...new Set(jsonData.map(item => item['人员']))];
        
        setMonths(uniqueMonths);
        setAreas(uniqueAreas);
        setEmployees(uniqueEmployees);
        alert('数据加载成功！');
      } catch (error) {
        console.error('处理文件失败:', error);
        alert('数据处理失败，请重试！');
      } finally {
        setIsUploading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    updateSalesData(selectedEmployee, selectedArea, month);
  };

  const handleEmployeeChange = (event) => {
    const employee = event.target.value;
    setSelectedEmployee(employee);
    updateSalesData(employee, selectedArea, selectedMonth);
  };

  const handleAreaChange = (event) => {
    const area = event.target.value;
    setSelectedArea(area);
    if (area) {
      const areaEmployee = excelData.find(item => item['地区'] === area)?.['人员'];
      if (areaEmployee) {
        setSelectedEmployee(areaEmployee);
        updateSalesData(areaEmployee, area, selectedMonth);
      }
    }
  };

  const updateSalesData = (employee, area, month) => {
    if (employee && area && month) {
      const employeeData = excelData.find(item => 
        item['人员'] === employee && item['地区'] === area
      );
      
      if (employeeData) {
        const monthlyData = {
          '人员': employeeData['人员'],
          '地区': employeeData['地区'],
          [month]: employeeData[month],
          '回款达成': employeeData[`${month}回款达成`],
          '退货': employeeData[`${month}退货`],
          '退货占比': employeeData[`${month}退货占比`],
          '销售': employeeData[`${month}销售`],
          '成本': employeeData[`${month}成本`],
          '毛利': employeeData[`${month}毛利`],
          '费用': employeeData[`${month}费用`],
          '利润': employeeData[`${month}利润`]
        };
        setSalesData(monthlyData);
      }
    }
  };

  return (
    <div className="salary-calculation">
      <div className="sc-header">
        <h1 className="sc-title">销售<span className="sc-title-highlight">数据</span></h1>
      </div>
      <button className="sc-back-button" onClick={() => navigate('/all-software')}>返回</button>
      
      <div className="sc-content">
        <div className="sc-file-upload">
          <input
            type="file"
            accept=".xlsx,.xls"
            onChange={handleFileUpload}
            disabled={isUploading}
            className="sc-file-input"
          />
          {isUploading && <span className="sc-upload-status">正在处理...</span>}
        </div>

        <div className="sc-selectors">
          <div className="sc-selector">
            <label>选择月份：</label>
            <select value={selectedMonth} onChange={handleMonthChange}>
              <option value="">请选择月份</option>
              {months.map(month => (
                <option key={month} value={month}>{month}</option>
              ))}
            </select>
          </div>

          <div className="sc-selector">
            <label>选择地区：</label>
            <select value={selectedArea} onChange={handleAreaChange}>
              <option value="">请选择地区</option>
              {areas.map(area => (
                <option key={area} value={area}>{area}</option>
              ))}
            </select>
          </div>

          <div className="sc-selector">
            <label>选择员工：</label>
            <select value={selectedEmployee} onChange={handleEmployeeChange}>
              <option value="">请选择员工</option>
              {employees.map(employee => (
                <option key={employee} value={employee}>{employee}</option>
              ))}
            </select>
          </div>
        </div>

        {salesData && (
          <div className="sc-salary-details">
            <h2>{salesData['人员']} - {salesData['地区']} {selectedMonth}销售数据</h2>
            <div className="sc-salary-grid">
              {Object.entries(salesData)
                .filter(([key]) => key !== '人员' && key !== '地区')
                .map(([key, value], index) => (
                  <div key={index} className="sc-salary-item">
                    <span className="sc-item-label">{key}</span>
                    <span className={`sc-item-value ${value < 0 ? 'negative' : 'positive'}`}>
                      {typeof value === 'number'
                        ? value.toLocaleString('zh-CN', {
                            style: 'currency',
                            currency: 'CNY',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        : value}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SalaryCalculation;